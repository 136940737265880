<template>
  <div>
    <!-- 联系我们 -->
    <div class="background-pattern-bottom">
      <div
        class="image"
        :style="[
          {
            backgroundImage:
              'url(' + require('../../assets/img/about/bg1.png') + ')',
          },
        ]"
      />
      <div>
        <div class="main">
          <p class="p-title">
            联系我们
          </p>
          <div class="mainbox">
            <div class="cardbox">
              <div>
                <card
                  v-for="(item,index) in list"
                  :key="index"
                  :active="active === index?true:false"
                  :img="item.img"
                  :imgshow="item.imgshow"
                  :index="index"
                  class="cardtop"
                  :label="item.label"
                  :sub-label="item.subLabel"
                  :click="clickCard"
                />
              </div>
              <div :key="active" class="show-r animated fadeInDown">
                <img
                  class="rimg"
                  :src="showRpic"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <div
          style="padding: 15rem 0 9rem 0;
          background-color: #F5F5F5;
          box-sizing: border-box;
          "
        >
          <imgCard :key="cc" />
        </div>
      </div>
    </div>
    <contact-us-footer-banner></contact-us-footer-banner>
  </div>
</template>

<script>
import card from './components/card.vue'
import imgCard from '@/components/Certify'
import ContactUsFooterBanner from '@/layout/footer/contactUsFooterBanner'
// import imgCard from './components/imgCard.vue'
export default {
  components: {
    ContactUsFooterBanner,
    card,
    imgCard
  },
  data() {
    return {
      cc: 0,
      active: 0,
      showRpic: require('../../assets/img/about/phoneR.png'),
      list: [
        {
          label: '服务热线:',
          subLabel: '400-829-9969',
          img: require('../../assets/img/about/phone.png'),
          imgR: require('../../assets/img/about/phoneR.png'),
          imgshow: require('../../assets/img/about/phone-show.png')
        },
        {
          label: '公司地址:',
          subLabel: '深圳市南山区北京航天航空大学大厦A座7楼7003',
          img: require('../../assets/img/about/poit.png'),
          imgR: require('../../assets/img/about/poitR.png'),
          imgshow: require('../../assets/img/about/poit-show.png')

        },
        {
          label: '邮箱:',
          subLabel: 'jingdan@wonwhale.com',
          img: require('../../assets/img/about/email.png'),
          imgR: require('../../assets/img/about/emailR.png'),
          imgshow: require('../../assets/img/about/email-show.png')

        }
      ]
    }
  },
  mounted() {
    const that = this
    window.onresize = function() {
      that.cc += 1
      console.log(that.cc, '88')
    }
  },
  methods: {
    clickCard(index) {
      this.showRpic = this.list[index].imgR
      this.active = index
    }
  }
}
</script>

<style lang="scss" scoped>
.imgcard{
  padding: 150px 0 90px 0;
  background-color: #F5F5F5;
}
.ic {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p-title {
  font-weight: bold;
  font-size: 5rem;
  margin: 80px 0;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
// 展示主盒子
.mainbox {
  padding: 100px 100px;
  margin-bottom: 50px;
  width: 80%;
  min-height: 300px;
  border-radius: 16px;
  box-shadow: 1px 1px 1px 1px #f1f1f1;
  background-color: rgb(255, 255, 255);
}

// card 边距
.cardtop + .cardtop {
  margin-top: 25px;
}

// card的分边
.cardbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// 右边的图片
.rimg {
  width: 335px;
  height: 335px;
  margin-left: 80%
}
// 展示右边
.show-r {
  display: unset;
}

// 背景图
.background-pattern-bottom {
  width: 100%;
  //   height: 150px;
  position: relative;
  //   bottom: -150px;
  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    background: no-repeat;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
<!-- 背景图end -->
<style lang="scss" scoped>
//  width:320px-479px
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .mainbox {
    padding: 30px;
    margin-bottom: 50px;
    width: 320px;
    min-height: 300px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px #f1f1f1;
    background-color: rgb(255, 255, 255);
  }
  .show-r {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .mainbox {
    padding: 50px;
    margin-bottom: 50px;
    width: 450px;
    min-height: 300px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px #f1f1f1;
    background-color: rgb(255, 255, 255);
  }
  .show-r {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
//  width:576px-767px
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mainbox {
    padding: 50px;
    margin-bottom: 50px;
    width: 520px;
    min-height: 300px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px #f1f1f1;
    background-color: rgb(255, 255, 255);
  }
  .show-r {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
//  width:768px-991px
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mainbox {
    padding: 50px;
    margin-bottom: 50px;
    width: 700px;
    min-height: 300px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px #f1f1f1;
    background-color: rgb(255, 255, 255);
  }
  .show-r {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
//  width:992px-1199px
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mainbox {
    padding: 50px;
    margin-bottom: 50px;
    width: 800px;
    min-height: 300px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px #f1f1f1;
    background-color: rgb(255, 255, 255);
  }
  .show-r {
    display: none;
  }
}
//  width:992px-1199px
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .mainbox {
    padding: 50px;
    margin-bottom: 50px;
    width: 900px;
    min-height: 300px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px #f1f1f1;
    background-color: rgb(255, 255, 255);
  }
  .show-r {
    display: none;
  }
}
</style>
