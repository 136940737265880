<template>
  <div class="mainbox1" @click="click(index)">
    <div class="mainboxcontent" :class="active?'choose':'nochoose'">
      <div>
        <img class="content-img1" :src="active?imgshow:img" alt="">
      </div>
      <div class="content-text">
        <p class="content-top">{{ label }}</p>
        <p class="content-sub">{{ subLabel }}</p>
      </div>
      <div>
        <img
          v-show="active"
          class="content-img2"
          src="../../../assets/img/about/line.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '服务热线：'
    },
    subLabel: {
      type: String,
      default: '400-822-9969'
    },
    img: {
      type: String,
      default: ``
    },
    imgshow: {
      type: String,
      default: ``
    },
    active: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    click: {
      type: Function,
      default: function() {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mainbox1 {
  width: 607px; //变
}

// choose
.choose {
  box-shadow: 0px 0px 10px rgba(56, 76, 254, 0.12) !important;
}

.nochoose {
  box-shadow: unset !important;
}

// 卡片
.mainboxcontent {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(56, 76, 254, 0.12);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 120px;
  background-color: rg
}

// 文字
.content-top {
  font-size: 24px;
}

.content-sub {
  font-size: 20px;
}

.content-text {
  flex: 1;
  text-align: left;
}

// 照片1
.content-img1 {
  // width: 50px;
  // height: 50px;
  margin: 0 30px 0 60px;
}

// 照片1
.content-img2 {
  width: 25px;
  height: 70px;
}
</style>
<style lang="scss" scoped>
//  width:320px-479px
@media only screen and (min-width: 320px) and (max-width: 479px) {

  .content-top {
    font-size: 16px;
  }
  .content-sub {
    font-size: 12px;
  }
  .mainbox1 {
    width: 260px !important; //变
  }
  // 卡片高度
  .mainboxcontent {
    height: 100px !important;
  }
  .content-text {
    flex: 1;
    text-align: left;
    font-size: 12px;
  }
  // 图片
  // 照片1
  .content-img1 {
    width: 41px;
    height: 41px;
    margin: 0 10px 0 15px;
  }
  // 照片1
  .content-img2 {
    width: 21px;
    height: 59px;
  }
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .content-top {
    font-size: 16px;
  }
  .content-sub {
    font-size: 12px;
  }
  .mainbox1 {
    width: 350px !important; //变
  }
  // 卡片高度
  .mainboxcontent {
    height: 120px !important;
  }
  .content-text {
    flex: 1;
    text-align: left;
    font-size: 16px;
  }
  // 图片
  // 照片1
  .content-img1 {
    // width: 25px;
    // height: 25px;
    margin: 0 15px 0 30px;
  }
  // 照片1
  .content-img2 {
    width: 21px;
    height: 59px;
  }
}
</style>

<style lang="scss" scoped>
//  width:576px-767px
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .content-top {
    font-size: 16px;
  }
  .content-sub {
    font-size: 13px;
  }
  .mainbox1 {
    width: 420px !important; //变
  }
  .mainboxcontent {
    height: 120px !important;
  }
  .content-text {
    flex: 1;
    text-align: left;
    font-size: 18px;
  }
  // 图片
  // 照片1
  .content-img1 {
    // width: 25px;
    // height: 25px;
    margin: 0 15px 0 30px;
  }
  // 照片1
  .content-img2 {
    width: 21px;
    height: 59px;
  }
}
</style>

<style lang="scss" scoped>
//  width:768px-991px
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mainbox1 {
    width: 600px !important; //变
  }
  .mainboxcontent {
    height: 120px !important;
  }
  // 图片
  // 照片1
  .content-img1 {
    // width: 25px;
    // height: 25px;
    margin: 0 15px 0 30px;
  }
  // 照片1
  .content-img2 {
    width: 21px;
    height: 59px;
  }
}
</style>

<style lang="scss" scoped>
//  width:992px-1199px
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mainbox1 {
    width: 700px !important; //变
  }
  .mainboxcontent {
    height: 120px !important;
  }
  // 图片
  // 照片1
  .content-img1 {
    // width: 50px;
    // height: 50px;
    margin: 0 30px 0 60px;
  }
  // 照片1
  .content-img2 {
    // width: 42px;
    // height: 118px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .mainbox1 {
    width: 800px !important; //变
  }
  .mainboxcontent {
    height: 120px !important;
  }
  // 图片
  // 照片1
  .content-img1 {
    // width: 50px;
    // height: 50px;
    margin: 0 30px 0 60px;
  }
  // 照片1
  .content-img2 {
    // width: 42px;
    // height: 118px;
  }
}
</style>
