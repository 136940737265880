/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 关于我们页面轮播图
*/

<template>
  <div id="certify" :key="cardWith">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img class="img-c" :src="require('../../assets/img/about/connectionone.png')">
        </div>
        <div ref="a" class="swiper-slide">
          <img class="img-c" :src="require('../../assets/img/about/connectiontwo.png')">
        </div>
        <div class="swiper-slide">
          <img class="img-c" :src="require('../../assets/img/about/connectionthree.png')">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from '@/docs/swiper-bundle.min'

export default {
  name: 'Certify',
  data() {
    return {
      cardWith: 520,
      imgList: [],
      certifySwiper: ''
    }
  },
  watch: {
    cardWith(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.cardWith = val
        console.log(val, ' 宽度变化')
        this.timer = true
        const that = this
        setTimeout(function() {
          // 打印screenWidth变化的值
          that.onsizechange()
          that.timer = false
        }, 600)
      }
    }
  },
  mounted() {
    // this.cardWith = this.$refs.a.offsetWidth
    this.onsizechange()
  },

  methods: {
    onsizechange() {
      const kuan = this.$refs.a.offsetWidth
      console.log(kuan, '4')
      this.$nextTick(() => {
        this.certifySwiper = new Swiper('#certify .swiper-container', {
          // resizeObserver: true,
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          autoplay: false,
          slideToClickedSlide: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          pagination: {
            el: '.swiper-pagination'
          // clickable :true,
          },
          on: {
            progress: function(progress) {
              console.log(this.slides.length)
              for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                var slideProgress = this.slides[i].progress
                let modify = 1
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.7 + 1
                }
                var translate = slideProgress * modify * (kuan / 2) + 'px'
                var scale = 1 - Math.abs(slideProgress) / 3
                var zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
                slide.transform(
                  'translateX(' + translate + ') scale(' + scale + ')'
                )
                slide.css('zIndex', zIndex)
                slide.css('opacity', 1)
                if (Math.abs(slideProgress) > 3) {
                  slide.css('opacity', 0)
                }
              }
            },
            setTransition: function(swiper, transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                slide.transition(transition)
              }
            }
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.img-c{
    width: 520px;
}
#certify {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#certify .swiper-container {
  display: flex;
  padding-bottom: 60px;
}

#certify .swiper-slide {
  width: 520px;
  background: #fff;
  box-shadow: 0 8px 30px #ddd;
}
#certify .swiper-slide img {
  display: block;
}

#certify .swiper-pagination {
  width: 100%;
  bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  border: 3px solid #fff;
  background-color: #d5d5d5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
  border: 3px solid #00aadc;
  background-color: #fff;
}
</style>

<style lang="scss" scoped>
//  width:320px-479px
@media only screen and (min-width:320px) and (max-width:479px) {
    #certify .swiper-slide {
    width: 250px !important; //宽同步
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
  }
  .img-c{
      width: 250px !important; //宽同步
  }
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width:480px) and (max-width:575px) {
    #certify .swiper-slide {
    width: 250px !important; //宽同步
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
  }
  .img-c{
      width: 250px !important; //宽同步
  }
}
</style>

<style lang="scss" scoped>
//  width:576px-767px
@media only screen and (min-width:576px) and (max-width:767px) {
    #certify .swiper-slide {
    width: 300px !important; //宽同步
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
  }
  .img-c{
      width: 300px !important; //宽同步
  }
}
</style>

<style lang="scss" scoped>
//  width:768px-991px
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #certify .swiper-slide {
    width: 400px !important; //宽同步
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
  }
  .img-c{
      width: 400px !important; //宽同步
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #certify .swiper-slide {
    width: 450px !important; //宽同步
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
  }
  .img-c{
      width: 450px !important; //宽同步
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
  #certify .swiper-slide {
    width: 650px !important; //宽同步
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
  }
  .img-c{
      width: 650px !important; //宽同步
  }
}
</style>
